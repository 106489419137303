<template>
  <div class="step-content">
    <completion-progress
      v-if="status=='ENR'"
      :completion="completion"
      class="px-8"
    />

    <v-card 
      outlined
      color="transparent"
      class="checklist text-left mx-n4 my-10"
    >
      <!-- <v-alert 
        v-if="status=='ENR'"
        :color="late ? 'error darken-1' : 'secondary'"
        transition="expand-transition"
        :icon="icons.mdiCalendarAlert"
        class="deadline body-1 rounded-b-0 pa-4 mb-0"
      >
        <span v-if="deadline==null">
          Carregando...
        </span>
        <span v-else>
          <b>Prazo: até {{ deadline | simpleDate }}</b> ({{ deadline | timeTill }})
        </span>
      </v-alert> -->
      <v-progress-linear
        v-if="deadline==null"
        indeterminate
        color="secondary"
        class="mb-0"
      />
      <v-list 
        v-else
        two-line
        color="transparent"
        class="feedbacks py-0 mt-0"
      >
        <v-list-item
          class="item"
          @click="toggleView('profile')"
        >
          <v-list-item-content>
            <v-list-item-title
              class="item-title"
            >
              Dados do Perfil
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            class="item-action"
          >
            <v-btn 
              v-if="user.status=='ENR'&&step.profile<1"
              text
              color="primary"
              class="mr-n2"
              @click="toggleView('profile')"
            >
              Preencher
            </v-btn>
            <v-btn 
              v-else-if="user.status=='FDD'"
              icon
              color="primary"
              @click.stop="toggleView('profile')"
            >
              <v-icon>{{ icons.mdiFileSearchOutline }}</v-icon>
            </v-btn>
            <v-btn 
              v-else
              icon
              color="primary"
              @click.stop="toggleView('profile')"
            >
              <v-icon>{{ icons.mdiCheck }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          v-for="item in step.docs.items"
          :key="item.criteria"
          class="item"
          @click="toggleDoc(true, item.criteria)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="item-title"
            >
              {{ docInfo(item.criteria).title | decodeWeek }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="item.status==3"
              class="item-feedback mt-2"
            >
              ⚠️ {{ item.feedback }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action
            class="item-action"
          >
            <v-btn 
              v-if="user.status=='ENR'&&docPending(item.status)&&!docs[item.criteria].loading"
              text
              color="primary"
              class="mr-n2"
              @click.stop="toggleDoc(true, item.criteria)"
            >
              Enviar
            </v-btn>
            <v-btn 
              v-else-if="user.status=='FDD'"
              icon
              color="primary"
              :loading="docs[item.criteria].loading"
              @click.stop="toggleDoc(true, item.criteria)"
            >
              <v-icon>{{ icons.mdiFileSearchOutline }}</v-icon>
            </v-btn>
            <v-btn 
              v-else
              icon
              color="primary"
              :loading="docs[item.criteria].loading"
              @click.stop="toggleDoc(true, item.criteria)"
            >
              <v-icon>{{ icons.mdiCheck }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

    </v-card>

    <slot name="quick-access" />

    <v-btn 
      text
      small
      class="help-btn"
      color="grey"
      @click="toggleHelp(true, 'signup')"
    >
      <v-icon
        small
        class="mr-4"
      >
        {{ icons.mdiHelpCircleOutline }}
      </v-icon>
      Precisa de ajuda?
    </v-btn>

    <doc-view
      :toggle="docView.toggle"
      :loading="docView.loading"
      :doc="docInfo(docView.selected)"
      :status="user.status"
      @send="sendDoc"
      @close="toggleDoc(false)"
    />


    <v-dialog
      v-model="confirm.toggle"
      overlay-opacity=".8"
      transition="slide-y-reverse-transition"
    >
      <v-card color="black">
        <v-card-title>Confirmar envio?</v-card-title>
        <v-card-text class="py-4">
          <p class="text-body-1">
            Ao enviar seus documentos para verificação, você garante que contém informações corretas e verídicas.
          </p>
          <p class="text-body-1">
            <b>Atenção:</b> Informações falsas podem resultar em bloqueio da conta e exclusão do processo seletivo. 
          </p>
          <p class="text-body-1">
            Confirmar envio dos documentos?
          </p>
        </v-card-text>

        <v-card-actions class="pb-4">
          <v-btn
            color="grey"
            text
            :disabled="confirm.loading"
            @click="toggleConfirm(false);"
            >
            Voltar
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :loading="confirm.loading"
            @click="requestDocEvaluation"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>

  .step-content .checklist {
    max-width: initial;
    border-color: var(--v-grey-darken3) !important;
  }

  .step-content .checklist .item-feedback {
    line-height: 1.4;
    text-overflow: initial;
    white-space: initial;
  }

  .step-content .checklist .deadline {
    margin: -1px;
  }

  .step-content .checklist .item:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-darken3) !important;
  }

</style>

<script>

  // Icons
  import {
    mdiHelpCircleOutline,
    mdiClose,
    mdiCalendarAlert,
    mdiCheck,
    mdiFileSearchOutline
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { docs } from '@/services/signup'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';
  const moment = require('moment');


  export default {
    name: 'Step3',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      appURL: {
        type: String,
        default: ''
      },
      refresh: {
        type: Boolean,
        default: false
      }
    },

    components: {
      CompletionProgress: () => import('@/components/CompletionProgress'),
      DocView: () => import('@/components/DocView'),
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
        mdiCalendarAlert,
        mdiCheck,
        mdiFileSearchOutline
      },
      docView: {
        toggle: false,
        selected: null,
        loading: false,
      },
      confirm: {
        toggle: false,
        loading: false
      },
      btnSend: {
        loading: false,
        disabled: false,
      },
      docs,
      deadline: null
    }),

    computed: {
      profileView: sync('app/views@profile'),
      user: sync('user'),
      status: sync('user/status'),
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),

      completion () {
        return _.round(_.mean([this.step.profile, this.step.docs.completion]) * 100);
      },

      stepName () {
        return this.$options.name.toLowerCase();
      },

      late () {
        return moment().isSameOrAfter(this.deadline);
      }
    },

    watch: {
      completion: { 
        immediate: true,
        handler (v) {
          this.step.cta.toggle = (v==100) && this.status=='ENR';
        }
      },
      refresh (b) {
        if (b) this.getDocsStatus();
      }
    },

    filters: {
      simpleDate (date) {
        return moment(date).format('DD/MM');
      },
      timeTill (date) {
        const today = moment().endOf('day');
        const left = moment(date).diff(today, 'd');
        return left >= 0 ? moment(today).to(date, true) : 'atrasado';
      },
      decodeWeek (text) {
        if (text.indexOf('*')>=0) {
          const breakdown = text.split('*');
          const n = parseInt(breakdown[1].substr(-1, 1));
          const start = moment().startOf('week').subtract(n, 'w').add(1, 'd');
          const end = moment().endOf('week').subtract(n, 'w').add(1, 'd');
          const format = start.month()==end.month() ? 'DD' : 'DD/MM';
          const week = start.format(format) + ' - ' + end.format('DD/MM');
          return text.replace('*week-'+n+'*', week);
        }else{
          return text;
        }
      }
    },

    methods: {
      ...services,

      actionCalled () {
        if (this.completion==100) {
          this.toggleConfirm(true);
        }
      },

      updateView () {
        this.getDocsStatus();
      },

      docInfo (doc) {
        if (doc==null) return null;
        const info = _.merge(_.clone(this.docs[doc]), this.step.docs.items[doc]);
        return info;
      },

      docPending (status) {
        return status==0 || status==3
      },

      toggleDoc (b, doc) {
        setTimeout(vue => {
          vue.docView.selected = typeof doc == 'undefined' ? null : doc;
          vue.docView.toggle = b;
        }, 250, this);
      },

      toggleConfirm (b) {
        this.confirm.toggle = b;
      },

      updateDocsCompletion () {
        const docs = this.step.docs.items;
        const count = _.countBy(docs, doc => {
          return doc.status==1||doc.status==2;
        })[true];
        this.step.docs.completion = typeof count == 'undefined' ? 0 : count / _.size(docs);
      },

      getDocsStatus () {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);

        this.$api.get('/getprofileinfodoc/'+cpf+'/'+token+'/?format=json')
          .then(response => {
            console.log('getDocsStatus => ',response);
            if(response.data.retorno==200){
              let docs = _.keyBy(JSON.parse(response.data.documentos), 'criteria');

              const owner = this.user.profile.vehicle.isOwner==1;
              docs = _.mapValues(docs, (doc, d) => {
                if (d=='doc_rg_prop') {
                  doc.required = owner ? 0 : 1;
                }
                return doc;
              });

              this.step.docs.items = _.pickBy(docs, ['required', 1]);

              this.updateDocsCompletion();
              this.setStepText();

              this.deadline = moment.utc(response.data.dt_Status).local().add(3, 'days').endOf('day');

              this.trackEvent('signup/'+this.stepName, 'get docs status ok');
            }else if(response.data.retorno==401) {
              this.getout();
              this.toggleToast(
                true,
                'Sua sessão expirou...',
                5000,
                false
              );
            }else{
              this.toggleToast(
                true,
                'Não foi possível carregar o status dos documentos.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'get docs status failed');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            if (this.refresh) this.$emit('onRefresh');
          });
      },

      sendDoc (photo) {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);
        // this.docView.loading = true;
        this.docs[photo.doc].loading = true;
        console.log(photo);

        this.$api({
          url: '/senddocdriver',
            method: 'POST',
            data: {
              cpf: cpf,
              authTk: token,
              tipo: photo.doc,
              filename: photo.filename,
              file: photo.blob
            }
          })
          .then(response => {
            console.log('sendDoc => ',response);
            if(response.data.retorno==200){
              this.step.docs.items[photo.doc].url_midia = response.data.url_midia;
              this.step.docs.items[photo.doc].status = 1;

              this.updateDocsCompletion();

              const next = _.find(this.step.docs.items, doc => doc.status==0 || doc.status==3);
              if (!_.isNil(next)&&!this.docView.toggle) this.toggleDoc(true, next.criteria);

              this.toggleToast(
                true,
                'Documento salvo 👍',
                3000,
                false,
              );

              this.trackEvent('signup/'+this.stepName, 'send doc ok');
            }else{
              this.toggleToast(
                true,
                'Não foi possível enviar seu documento. Tente novamente, por favor.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'send doc failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível enviar seu documento. Tente novamente, por favor.', true);
          })
          .finally(() => {
            // this.docView.loading = false;
            this.docs[photo.doc].loading = false;
          });
      },

      requestDocEvaluation () {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);
        this.confirm.loading = true;

        this.$api({
          url: '/requestdocevaluationdriver',
            method: 'POST',
            data: {
              cpf: cpf,
              authTk: token,
            }
          })
          .then(response => {
            console.log('requestDocEvaluation => ',response);
            if(response.data.retorno==200){
              this.step.cta.toggle = false;
              this.toggleConfirm(false);
              this.status = response.data.status_motorista;

              this.setStepText();

              this.toggleToast(
                true,
                'Documentos enviados com sucesso! 👍',
                5000,
                false,
              );

              this.trackEvent('signup/'+this.stepName, 'request doc evaluation ok');
            }else{
              this.getDocsStatus();
              this.toggleToast(
                true,
                'Não foi possível enviar seus documentos. Tente novamente, por favor.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'request doc evaluation failed');
            }
          })
          .catch(error => {
            this.handleError(error, 'Não foi possível enviar seus documentos. Tente novamente, por favor.', true);
          })
          .finally(() => {
            this.confirm.loading = false;
          });
      },

      setStepText () {
        if (this.status=='FDD') {
          this.step.title = 'Documentos Enviados';
          this.step.subtitle = 'Vamos conferir e deixar tudo pronto! Não temos previsão de abertura de vaga, mas caso aconteça enviaremos notificação e e-mail para você!';
        }else{
          this.step.title = 'Envie seus documentos';
          this.step.subtitle = 'Neste passo precisamos que preencha mais informações de perfil, e nos envie os documentos abaixo:';
        }
      }

    },

    created () {
    },

    mounted () {
      this.getDocsStatus();
    },

    directives: {
    },

  }
</script>
